import React, { useEffect, useState } from 'react';

import './Reload.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Reload = ({ setFetchError, setLoading }) => {
    const navigate = useNavigate();

    const handleReload = () => {
            setFetchError(false);
            setLoading(true);
            window.location.reload();
        }

    const handleGoBack = () => {
        navigate(-1);
        // navigate.
    }

  return (
    <div className='reload'>
        <div className='container'>
            <h1>Something went wrong :&#40;</h1>
            <p>Try reloading the page!</p>
            <div style={{ display: 'flex', gap: '8px'}}>
                <button onClick={handleGoBack}>Back</button>
                <button onClick={handleReload}>Reload</button>
            </div>
        </div>
    </div>
  );
}

export default Reload;