
// BASE URL
// export const BASE_URL = "http://localhost:1337";
export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// console.log('BASE URL - ', BASE_URL)

// Authentication
export const SIGNIN = `${BASE_URL}/api/auth/local`;
export const SIGNUP = `${BASE_URL}/api/auth/local/register`;
export const FORGOT_PASSWORD = `${BASE_URL}/api/auth/forgot-password`;
export const RESET_PASSWORD = `${BASE_URL}/api/auth/reset-password`;
export const CHANGE_PASSWORD = `${BASE_URL}/api/auth/local/change-password`;
export const EMAIL_CONFIRMATION = `${BASE_URL}/api/auth/local/email-confirmation`;
export const SEND_EMAIL_CONFIRMATION = `${BASE_URL}/api/auth/local/send-email-confirmation`;

// Pages
export const USERS = `${BASE_URL}/api/users`;
export const USER = (id) => `${BASE_URL}/api/users/${id}?populate=*`;
export const ME = `${BASE_URL}/api/users/me`;
export const BIOGRAPHIES = `${BASE_URL}/api/biographies`;
export const TIMELINES = `${BASE_URL}/api/timelines`;
export const MEDIAS = `${BASE_URL}/api/images`;
export const TRIBUTES = `${BASE_URL}/api/tributes`;
export const USER_CODES = `${BASE_URL}/api/user-codes`;

export const SAVE_BIOGRAPHY_BY_ID = (id) => `${BASE_URL}/api/biographies/${id}`;
export const SAVE_BIOGRAPHY= `${BASE_URL}/api/biographies`;
export const SAVE_TIMELINE_BY_ID = (id) => `${BASE_URL}/api/timelines/${id}`;
export const SAVE_TIMELINE= `${BASE_URL}/api/timelines`;
export const SAVE_MEDIA_VIDE0_BY_ID = (id) => `${BASE_URL}/api/images/${id}`;
export const SAVE_MEDIA= `${BASE_URL}/api/images`;
export const SAVE_MEDIA_IMAGE_BY_ID = `${BASE_URL}/api/upload`;
export const SAVE_TRIBUTE_BY_ID = (id) => `${BASE_URL}/api/tributes/${id}`;
export const SAVE_TRIBUTE = `${BASE_URL}/api/tributes`;
export const SAVE_COMMENT_BY_ID = (id) => `${BASE_URL}/api/comments/${id}`;
export const SAVE_COMMENT = `${BASE_URL}/api/comments`;

// Delete
export const DELETE_MEDIA_IMG = (id) => `${BASE_URL}/api/upload/files/${id}`;

export const USER_CODE = (id) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${id}`;
export const ALL_RELATIONS_BY_USER_CODE = (id) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${id}&populate=*`;
export const BIOGRAPHY_BY_USER_CODE = (id) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${id}&populate[biography][populate]=picture,coverPhoto`;
export const TIMELINE_BY_USER_CODE = (id) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${id}&populate[timeline][populate]=dateEvent`;
export const MEDIA_BY_USER_CODE = (id) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${id}&populate[media][populate]=images,videos`;
// export const TRIBUTE_BY_USER_CODE = (id) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${id}&populate[tribute][populate]=comments`;

export const TRIBUTE_BY_USER_CODE = (id) => `${BASE_URL}/api/tributes?filters[user_code][slug][$eq]=${id}&populate[comments][populate][users_permissions_user][fields][0]=firstName&populate[comments][populate][users_permissions_user][fields][1]=lastName&populate[comments][populate][users_permissions_user][fields][2]=photo`;


export const GET_USER_DETAILS_BY_USER_CODE = (user_code) => `${BASE_URL}/api/user-codes?filters[slug][$eq]=${user_code}&populate=users_permissions_user`;

// http://localhost:1337/api/user-codes?filters[slug][$eq]=MLplQ8X23&populate[biography][populate]=picture,coverPhoto

