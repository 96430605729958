import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Common/Loading';
import Navbar from '../../Common/Navbar';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';


const Dashboard = () => {
  const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState(null);
    const [userCode, setUserCode] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const { authState } = useAuth();
    const [role, setRole] = useState('Public');
  
    const handleBuyNow = () => {
      window.location.replace('https://memorialtag.nl/products/memorialtag');
    }

    const handleNavigate = () => {
      navigate(`/${userCode}`);
    }

    useEffect(() => {
      setLoading(true);
      const fetchDashboard = async () => {
        // const result = await getBiographyByUserCode(userCode);
        // setBiography(result?.data[0]?.attributes?.biography?.data?.attributes)
        // if (result.error) {
        //     setError(true);
        // }
        setLoading(false);
      };
      fetchDashboard();
    }, []);

    useEffect(() => {
        if (authState?.authenticated === true) {
            setRole(authState?.user?.role?.name);
            setUserCode(authState?.user?.user_code?.slug);
            setAuthenticated(true);
        } else {
          navigate('/signin');
        }
    }, [authState]);

  return (
            !loading ?
            <>
                <Navbar />
                (<div className='dashboard'>
                    <div className='dashboard-container'>
                        {userCode === '' || userCode === null || userCode === undefined ?
                        <>
                            <p>{t("no memorial tag")}.</p>
                            <button onClick={handleBuyNow}>{t("buy now")}</button>
                        </>
                        :
                        <>
                            <p>{t("your memorial tag id")}: </p>
                            <p onClick={handleNavigate} className='tag-id'>{`https://memorialtag.eu/${userCode}`}</p>
                        </>}
                    </div>
                </div>)
            </>
            :
            <Loading />
  );
}

export default Dashboard;
