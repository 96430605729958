import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { nl, enUS } from 'date-fns/locale'; // Import multiple locales

// Detect user's browser language
const userLanguage = navigator?.language?.startsWith('nl') ? nl : enUS; 
// const userLanguage = nl;

function C(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDate(dateString, locale = userLanguage) {
    if (dateString === undefined || dateString === null) { return }
    const date = new Date(dateString);
    return C(format(date, 'd MMMM yyyy', { locale }));
}


export function formatDateForTimeline(dateString, locale = userLanguage) {
    if (dateString === undefined || dateString === null) { return }
    const date = new Date(dateString);
    return C(format(date, 'MMMM d, yyyy', { locale }));
}


export function formatRelativeTime(dateString, locale = userLanguage) {
  const date = parseISO(dateString);
  return C(formatDistanceToNow(date, { addSuffix: true, locale}));
}