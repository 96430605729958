import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className='tag-loading'>
      <div className='loader'>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
}

export default Loading;