import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingForButton from '../../Common/LoadingForButton';
import { FORGOT_PASSWORD } from '../../../service/api';
import Notification from '../../Common/Notification';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';

const ForgotPassword = () => {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSend = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setLoading(true);

        try {
            const response = await axios.post(FORGOT_PASSWORD, {
                email,
            });
            setSuccess(t("auth.checkYourInbox"));
            setTimeout(() => {
                navigate('/signin');
            }, 3000);
        } catch (err) {
            setError('An error occurred, Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className='page'>
            <div className='container'>
                <h1>Forgot Password</h1>
                <form onSubmit={handleSend}>
                    <TextField 
                        id="email" 
                        label={t("auth.email")}
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        required
                        variant="outlined" 
                    />

                    {error && (
                        <Notification
                        type='error'
                        message={error}
                        duration={3000}
                        onClose={() => setError(null)}
                        />
                    )}
                    {success && (
                        <Notification
                        type='success'
                        message={success}
                        duration={3000}
                        onClose={() => setSuccess(null)}
                        />
                    )}

                    <button type="submit" disabled={loading}>
                        {loading ? <LoadingForButton /> : t("auth.send")}
                    </button>
                </form>
            </div>
        </section>
    );
};

export default ForgotPassword;
