import React, { useEffect, useState } from 'react';
import { MenuOutline } from 'react-ionicons';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/memorial-tag-logo.png';
import './Navbar.css';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';

export default function Navbar() {
  const { t } = useTranslation();
  const { authState, onLogout } = useAuth();
  const [authenticated, setAuthenticated] = useState(false);
  const [userCode, setUserCode] = useState();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClick = (e, id) => {
    e.preventDefault();
    if (id === 1) {
      if (authenticated === true) {
        if (userCode) {
          navigate(`/${userCode}`);
        } else {
          navigate('/');
        }
      } else {
        navigate('/signin');
      }
    }
    else if (id === 2) {
      window.location.replace('https://memorialtag.nl/products/memorialtag');
      return
    }
    else if (id === 3) {
      window.location.replace('https://memorialtag.nl/pages/contact');
      return
    }
    else if (id === 4) {
      handleLogout();
    }
  }
  const handleLogout = async () => {
    await onLogout();
    navigate('/signin');
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (authState?.authenticated === true) {
      setAuthenticated(true)
      setUserCode(authState?.user?.user_code?.slug);
    }
  }, [authState])

  const navList = [
    {
      id: 1,
      name: t("nav.myAccount"),
      icon: <PersonPinIcon />
    },
    {
      id: 2,
      name: t("nav.visitStore"),
      icon: <LocalMallIcon />
    },
    {
      id: 3,
      name: t("nav.contactUs"),
      icon: <RecentActorsIcon />
    },
    {
      id: 4,
      name: t("nav.logout"),
      icon: <LogoutIcon />
    }
  ]

  const DrawerList = (
    <Box 
      sx={{ 
        width: 250, 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between' 
      }} 
      role="presentation" 
      onClick={toggleDrawer(false)}
    >
      <List>
        {navList.map(text => (
          authenticated === false && text.id === 4 ?
          null
          :
          <ListItem key={text.id} disablePadding onClick={(e) => handleClick(e, text.id)}>
            <ListItemButton>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      <Divider sx={{ marginTop: 1 }} />
      </List>
      <p style={{ fontSize: 12,marginTop: 24, padding: 16, fontFamily: 'Century Gothic' }}>Memorial Tag 1.0.0</p>
    </Box>
  );

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <div 
          onClick={toggleDrawer(true)} 
          id='navbar-toggle' className='navbar-toggle'>
            <MenuOutline
              className='icon'
              style={{
                color: '#2B2B2B',
                width: '32px',
                height: '32px'
              }}
          />
          <img className='navbar-logo' src={logo} />
          <div style={{ width: 32, height: 32 }} />
          </div>
        </div>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </nav>
      {/* {isOpen && <div className="overlay" onClick={toggleNavbar}></div>} */}
    </>
  );
}

