import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Biography from './components/Pages/Biography';
import Timeline from './components/Pages/Timeline';
import Media from './components/Pages/Media';
import Tribute from './components/Pages/Tribute';
import SignIn from './components/Authentication/SignIn';
import SignUp from './components/Authentication/SignUp';
import NotFound from './components/Common/NotFound';
import Dashboard from './components/Pages/Dashboard';
import ForgotPassword from './components/Authentication/ForgotPassword';
import ResetPassword from './components/Authentication/ResetPassword';

import { useTranslation } from 'react-i18next';
import './language/i18n';

import './App.css';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);

  // useEffect(() => {
  //   // Get the user's browser language
  //   const browserLanguage = navigator.language || navigator.userLanguage;
  
  //   // If the browser language is Dutch, set it to 'nl', otherwise default to Dutch ('nl')
  //   const defaultLanguage = browserLanguage.startsWith('nl') ? 'nl' : 'nl';
  //   i18n.changeLanguage(defaultLanguage);
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/signin' element={<SignIn />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
        <Route exact path="/:userCode" element={<Biography />} />
        <Route path='/:userCode/timeline' element={<Timeline />} />
        <Route path="/:userCode/media" element={<Media />} />
        <Route path='/:userCode/tribute' element={<Tribute />} />
        <Route path='/not-found' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
