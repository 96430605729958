import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Common/Layout';
import { getBiographyByUserCode, saveBiographyById } from '../../../service/serviceHelper';
import { useAuth } from '../../../context/AuthContext';
import { TextField } from '@mui/material';
import Notification from '../../Common/Notification';
import './Biography.css';
import LoadingForButton from '../../Common/LoadingForButton';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';


export default function Biography() {
  const { t, i18n } = useTranslation();
  const { userCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [biography, setBiography] = useState(null);
  const { authState } = useAuth();
  const [validationError, setValidationError] = useState(null);
  const [owner, setOwner] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const validateBiography = () => {
    if (
      !biography.firstName || 
      !biography.lastName || 
      !biography.birthday || 
      !biography.deathday || 
      !biography.content
    ) {
      setValidationError(t("all fields are required"));
      return false;
    }
    setValidationError('');
    return true;
  };
  
  const handleSave = async () => {
    setSaving(true);
    const saveBiography = async () => {
      const data = {
        "firstName": biography.firstName,
        "lastName": biography.lastName,
        "birthday": biography.birthday,
        "deathday": biography.deathday,
        "content": biography.content
      }
      
      const result = await saveBiographyById(biography.id, data, authState?.user);
      if (result.error) {
        setError(result.msg);
      } else {
        setSuccess(t("saved successful"));
      }
      setSaving(false);
    }
    saveBiography();
    fetchBiography();
  }
  
  const handleCancel = () => {
    setValidationError("");
    setEditing(false);
  }

  const handleEditing = () => {
    if (editing) {
      if (!validateBiography()) {
        return;
      }
      // Save biography changes here if needed
      handleSave();
    }
    setEditing(!editing);
  }

  const fetchBiography = async () => {
    const result = await getBiographyByUserCode(userCode);
    if (result.error) {
        setError(t("an error occurred, please try again"));
    } else {
      // console.log('the bio - ', result)
      setBiography(
        {
          id: result?.data[0]?.attributes?.biography?.data?.id,
          ...result?.data[0]?.attributes?.biography?.data?.attributes
        } || null)
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchBiography();
  }, [])
  
  useEffect(() => {
    if (authState?.user?.user_code?.slug === userCode) {
      setOwner(true);
    }
  }, [authState])


  return (
    <Layout userCode={userCode} loading={loading} setLoading={setLoading} saving={saving}>
    { owner !== true ? null : 
      <div className='button-edit'>
        {editing && !saving ? <button onClick={handleCancel} className='biography-add'>{t("cancel")}</button> : null}
        {saving ? <button className='biography-add'><LoadingForButton /></button> : <button className='biography-add' onClick={handleEditing}>{editing ? t("save") : t("edit")}</button>}
      </div>
    }

    {
      editing ? 
      <BiographyEdit 
        biography={biography} 
        setBiography={setBiography} 
        handleSave={handleSave}
        validationError={validationError} 
        setValidationError={setValidationError}
        error={error}
        setError={setError}
      />
      :
      <BiographyCurrent biography={biography} />
    }

    {error && (
      <Notification
      type='error'
      message={error}
      duration={3000}
      onClose={() => setError(null)}
      />
    )}
    

    {success && (
      <Notification
      type='success'
      message={success}
      duration={3000}
      onClose={() => setSuccess(null)}
      />
    )}
    </Layout>
  );
}




const BiographyEdit = ({
  biography,
  setBiography,
  validationError,
  setValidationError,
}) => { 

  const { t } = useTranslation();

  const handleChange = (e) => {
  const { name, value } = e.target;
  setBiography({
    ...biography,
    [name]: value
  });
}

  
  return (
    <div className='biography-edit'>
      <TextField 
          label={t("auth.firstName")} 
          type="text"
          name="firstName"
          value={biography.firstName}
          onChange={handleChange}
          required
          variant="outlined" 
          InputLabelProps={{ shrink: true }}
      />
      <TextField 
          label={t("auth.lastName")}
          type="text"
          name="lastName"
          value={biography.lastName}
          onChange={handleChange}
          required
          variant="outlined" 
          InputLabelProps={{ shrink: true }}
      />
      <TextField 
          label={t("biography.birthday")}
          type="date"
          name="birthday"
          value={biography.birthday}
          onChange={handleChange}
          required
          variant="outlined" 
          InputLabelProps={{ shrink: true }}
      />
      <TextField 
          label={t("biography.deathday")}
          type="date"
          name="deathday"
          value={biography.deathday}
          onChange={handleChange}
          required
          variant="outlined" 
          InputLabelProps={{ shrink: true }}
      />
      <TextField
        label={t("biography.content")}
        multiline
        rows={7}
        name="content"
        value={biography.content}
        onChange={handleChange}
        required
        variant="outlined" 
        InputLabelProps={{ shrink: true }}
      />

      {validationError && (
        <Notification
        type='error'
        message={validationError}
        duration={3000}
        onClose={() => setValidationError(null)}
        />
      )}

    </div>
  )
}


const BiographyCurrent = ({
  biography
}) => {
  const { t } = useTranslation();
  
  return (
    <div className='biography'>
      {biography?.id !== undefined && biography?.content?.length !== 0 ?
        <p>{ biography?.content }</p>
        :
        <p style={{ textAlign: 'center', fontFamily: 'var(--font-family)', fontWeight: '600' }}>{t("biography.noBiographyAdded")}</p>
      }
    </div>
  )
}