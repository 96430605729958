import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './Media.css';
import { Camera, CameraOutline, Recording, RecordingOutline, PencilOutline, TrashBin } from 'react-ionicons';
import Layout from '../../Common/Layout';
import { deleteMediaImgById, getMediaByUserCode, saveMediaImageById, saveMediaVideoById } from '../../../service/serviceHelper';
import { BASE_URL } from '../../../service/api';
import Notification from '../../Common/Notification';
import { useAuth } from '../../../context/AuthContext';
import LoadingForButton from '../../Common/LoadingForButton';
import { TextField } from '@mui/material';
import { t } from 'i18next';

export default function Media() {
  const { userCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState({ images: [], videos: [] });
  const [editing, setEditing] = useState(false);
  const [currentMedia, setCurrentMedia] = useState('images');
  const [validationError, setValidationError] = useState('');
  const { authState } = useAuth();
  const [owner, setOwner] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [pendingImages, setPendingImages] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (authState?.user?.user_code?.slug === userCode) {
      setOwner(true);
    }
  }, [authState, userCode]);

  const fetchMedia = async () => {
    try {
      const result = await getMediaByUserCode(userCode);
      if (result.error) {
        setValidationError(t("media.errorFetchingMedia"));
      } else {
        const mediaId = result?.data[0]?.attributes?.media?.data?.id;
        const imagesObject = result?.data[0]?.attributes?.media?.data?.attributes?.images || {};
        const videosObject = result?.data[0]?.attributes?.media?.data?.attributes?.videos || {};

        const imagesArray = Object.values(imagesObject);
        const videosArray = Object.values(videosObject);

        // const updatedImages = imagesArray.map(img => ({
        //   ...img,
        //   attributes: {
        //     ...img.attributes,
        //     url: BASE_URL + img.attributes.url
        //   }
        // }));

        setMedia({
          id: mediaId,
          images: imagesArray,
          videos: videosArray
        });
      }
    } catch (error) {
      setValidationError(t("media.errorFetchingMedia"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMedia();
  }, [userCode]);

  const handleDelete = async (id, index) => {
    setSaving(true);
    try {
      const result = await deleteMediaImgById(id, index, media);
      if (result.error) {
        setError(t("an error occurred, please try again"));
      } else {
        const updatedImages = media.images.filter((_, i) => i !== index);
        setMedia(prevMedia => ({
          ...prevMedia,
          images: updatedImages
        }));
        setSuccess(t("delete successful"));
      }
    } catch (err) {
      setError(t("an error occurred, please try again"));
    } finally {
      setSaving(false);
    }
  };

  const handleEditing = () => {
    setEditing(!editing);
  };

  const handleImageSave = async (file = null) => {
    if (file) {
      setSaving(true);
      try {
        const result = await saveMediaImageById(media.id, file, authState?.user, media);
        if (result.error) {
          setError(t("an error occurred, please try again"));
        } else {
          setSuccess(t("saved successful"));
          fetchMedia();
          setPendingImages(prev => prev.filter(img => img.id !== file.name)); // Remove pending image after save
        }
      } catch (error) {
        setError(t("an error occurred, please try again"));
      } finally {
        setSaving(false);
      }
    }
  };

  const handleMediaChange = (value) => {
    setCurrentMedia(value);
  };

  return (
    <Layout userCode={userCode} loading={loading} setLoading={setLoading} saving={saving}>
      {owner && (
        <div className='button-edit'>
          <button className='media-add' onClick={handleEditing}>
            {editing ? t("media.liveMode") : t("media.editMode")}
          </button>
        </div>
      )}

      <div className='media'>
        <div className='media-nav'>
          <div className='media-link' onClick={() => handleMediaChange("images")}>
            {currentMedia === "images" ? (
              <>
                <Camera style={{ color: '#D6C7A1' }} />
                <div className='indicator'></div>
              </>
            ) : (
              <CameraOutline style={{ color: '#2B2B2B' }} />
            )}
          </div>
          <div className='media-link' onClick={() => handleMediaChange("videos")}>
            {currentMedia === "videos" ? (
              <>
                <Recording style={{ color: '#D6C7A1' }} />
                <div className='indicator'></div>
              </>
            ) : (
              <RecordingOutline style={{ color: '#2B2B2B' }} />
            )}
          </div>
        </div>

        <MediaCurrent 
          newFile={newFile} 
          setNewFile={setNewFile} 
          media={media} 
          currentMedia={currentMedia} 
          editing={editing} 
          setMedia={setMedia} 
          handleDelete={handleDelete}
          handleImageSave={handleImageSave}
          validationError={validationError}
          setValidationError={setValidationError}
          saving={saving}
          setSaving={setSaving}
          error={error}
          setError={setError}
          success={success}
          setSuccess={setSuccess}
          fetchMedia={fetchMedia}
          pendingImages={pendingImages}
          setPendingImages={setPendingImages}
        />
      </div>

      {validationError && <Notification type='error' message={validationError} duration={3000} onClose={() => setValidationError('')} />}
      {error && <Notification type='error' message={error} duration={3000} onClose={() => setError('')} />}
      {success && <Notification type='success' message={success} duration={3000} onClose={() => setSuccess('')} />}
    </Layout>
  );
}

const MediaCurrent = ({ 
  setNewFile, 
  media, 
  currentMedia, 
  editing, 
  setMedia,
  handleDelete,
  handleImageSave,
  validationError,
  setValidationError,
  saving,
  setSaving,
  setError,
  setSuccess,
  setPendingImages
}) => {
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const blobUrl = URL.createObjectURL(file);
      const id = file.name; // Use file name as a temporary id
      
      // Add to pending images state
      setPendingImages(prev => [
        ...prev,
        {
          id,
          attributes: {
            url: blobUrl,
            name: file.name
          }
        }
      ]);

      setNewFile(file);
      handleImageSave(file);
    }
  };

  const validateVideoUrls = () => {
    for (const video of media?.videos) {
      if (!video) {
        setValidationError(t("all fields are required"));
        return false;
      }
    }
    setValidationError('');
    return true;
  };
  
  const handleVideoSave = async () => {
    if (!validateVideoUrls()) {
      return;
    }

    setSaving(true);
    const saveVideo = async () => {
      const data = {
        "videos": media?.videos
      }
      
      const result = await saveMediaVideoById(media.id, data);
      if (result.error) {
        setError(t("an error occurred, please try again"));
      } else {
        setSuccess('saved successful');
      }
      setSaving(false);
    }
    saveVideo();
  }

  return (
    <div className='media-results'>
      {currentMedia === "images" ? (
        <div className='media-images'>
          <>
            {media?.images?.map((img, index) => (
              <div key={index} className='images-container'>
                {editing && (
                  <div className='icon-container'>
                    <TrashBin onClick={() => handleDelete(img.id, index)} />
                  </div>
                )}
                <img src={`https://memorialtag.s3.amazonaws.com/media/${img?.url}`} alt="Media" />
              </div>
            ))}
            { editing ?
              <div className='images-container empty'>
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  <div className='icon-container-empty'>
                    <PencilOutline onClick={() => fileInputRef.current.click()} />
                  </div>
                </>
              </div>
              :
              null
            }
          </>
          {media?.images?.length === 0 && !editing ? (
            <p>{t("media.noImagesAdded")}</p>
          ) : (
            null
          )}
        </div>
      
      ) : (
        <div style={{ width: '100%' }}>
          {
            editing ? 
            <VideoUrlEdit
              media={media}
              setMedia={setMedia}
              validationError={validationError}
              setValidationError={setValidationError}
              saving={saving}
              handleVideoSave={handleVideoSave}
            />
            :
            media.videos.length > 0 ? (
              media.videos.map((video, index) => (
                <div key={index} className="media-videos">
                  <iframe
                    width="100%"
                    height="315"
                    src={video.url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded YouTube"
                  ></iframe>
                </div>
              ))
            ) : (
              <p>{t("media.noVideoAdded")}</p>
          )}
        </div>
      )}
    </div>
  );
};

const VideoUrlEdit = ({
  media,
  setMedia,
  saving,
  validationError,
  setValidationError,
  handleVideoSave
}) => { 
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newVideo = [...media?.videos];
    newVideo[index] = {
      ...newVideo[index],
      [name]: value,
    };
    setMedia({
      ...media,
      "videos": newVideo
    });
  }

  const handleRemove = () => {
    if (media?.videos?.length !== 0) {
      setMedia({
        ...media,
        "videos": media?.videos?.splice(0, media?.videos?.length-1)
      });
    }
  }

  const handleAdd = () => {
    setMedia({
      ...media,
      "videos": [
      ...media?.videos,
      {
        'url': ""
      }]
    });
  }

  return (
    <div className='timeline-edit'>
      { media?.videos?.length > 0 ?
        media?.videos?.map((video, index) => (
          <div key={index} className='timeline-labels'>
            <TextField 
              label="Video" 
              type="text"
              name="url"
              value={video.url}
              onChange={(e) => handleChange(e, index)}
              required
              variant="outlined" 
              InputLabelProps={{ shrink: true }}
            />
          </div>
        ))
        :
        <p className='info'>{t("media.addYoutubeLinks")}</p>
      }
      <div className='button-edit'>
        {saving ? <button className='media-add'><LoadingForButton /></button> :
          <>
            <button className='media-add' onClick={handleRemove}>{t("remove")}</button>
            <button className='media-add' onClick={handleAdd}>{t("new")}</button>
            <button className='media-add' onClick={handleVideoSave}>{t("save")}</button>
          </>
        }
      </div>

      {validationError && (
        <Notification
          type='error'
          message={validationError}
          duration={3000}
          onClose={() => setValidationError(null)}
        />
      )}
    </div>
  )
}
