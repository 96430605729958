import React, { createContext, useContext, useEffect, useState } from "react";
import axios from 'axios';
import { SIGNIN, SIGNUP, ME, USER } from "../service/api";

const Token = 'token';
const User = 'user';

const AuthContext = createContext({});

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: null,
        authenticated: null,
        user: null
    });

    useEffect(() => {
        const loadAuthState = async () => {
            const token = localStorage.getItem(Token);
            const user = localStorage.getItem(User);

            if (token && user) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                setAuthState({
                    token: token,
                    authenticated: true,
                    user: JSON.parse(user)
                });
            }
        }
        loadAuthState();
    }, []);

    const signup = async (userData) => {
        try {
            const result = await axios.post(`${SIGNUP}`, { ...userData });
            const token = result.data?.jwt;
            let user = result.data?.user;

            // Fetch full user data
            if (token) {
                const fullUserData = await fetchFullUserData(token, user?.id);
                if (fullUserData) {
                    user = fullUserData;
                }
            }

            setAuthState({
                token: token,
                authenticated: true,
                user: user
            });

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            localStorage.setItem(Token, token);
            localStorage.setItem(User, JSON.stringify(user));
        } catch (err) {
            return {
                error: true,
                msg: err?.response?.data?.error?.message || 'Signup failed'
            }
        }
    }

    const fetchFullUserData = async (token, id) => {
        try {
            const response = await axios.get(`${USER(id)}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.data;
        } catch (err) {
            // console.error('Error fetching full user data:', err);
            return null;
        }
    }

    const login = async (identifier, password) => {
        try {
            const result = await axios.post(`${SIGNIN}`, { identifier, password });
            const token = result.data?.jwt;
            let user = result.data?.user;

            // Fetch full user data
            if (token) {
                const fullUserData = await fetchFullUserData(token, user?.id);
                if (fullUserData) {
                    user = fullUserData;
                }
            }

            setAuthState({
                token: token,
                authenticated: true,
                user: user
            });

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            localStorage.setItem(Token, token);
            localStorage.setItem(User, JSON.stringify(user));

        } catch (err) {
            // console.log('the error - ', err)
            return {
                error: true,
                msg: err?.response?.data?.error?.message || 'Login failed'
            }
        }
    }

    const logout = async () => {
        localStorage.removeItem(Token);
        localStorage.removeItem(User);
        axios.defaults.headers.common['Authorization'] = '';
        setAuthState({
            token: null,
            authenticated: false,
            user: null
        });
    }

    const value = {
        onSignup: signup,
        onLogin: login,
        onLogout: logout,
        authState
    };
    
    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    );
}